// eslint-disable-next-line @next/next/no-server-import-in-page
import type { NextRequest } from 'next/server';
import { nanoid } from 'nanoid';
import { SignJWT, jwtVerify } from 'jose';
import { Constant } from '@lib/Constant';
import { AppError, AppErrorCode } from '@lib/AppError';
import { differenceInMinutes } from 'date-fns';
import { GetServerSideProps } from 'next';
import localforage from 'localforage';

type NRequest =
  | { headers: NextRequest['headers'] }
  | { headers: Parameters<GetServerSideProps>[0]['req']['headers'] };

export class UserAccessToken {
  public readonly jti: string;
  public readonly iat: number;
  public readonly userId: string;
  private readonly token: string;

  private constructor(props: {
    jti: UserAccessToken['jti'];
    iat: UserAccessToken['iat'];
    userId: UserAccessToken['userId'];
    token: UserAccessToken['token'];
  }) {
    this.jti = props.jti;
    this.iat = props.iat;
    this.userId = props.userId;
    this.token = props.token;
  }

  static async fromData(data: {
    userId: UserAccessToken['userId'];
  }): Promise<UserAccessToken> {
    const userAccessTokenString = await new SignJWT(data)
      .setProtectedHeader({ alg: 'HS256' })
      .setJti(nanoid())
      .setIssuedAt()
      .sign(new TextEncoder().encode(Constant.JWT_SECRET_KEY));

    return this.fromString(userAccessTokenString);
  }

  static async fromString(token: string): Promise<UserAccessToken> {
    const { payload } = await jwtVerify(
      token,
      new TextEncoder().encode(Constant.JWT_SECRET_KEY)
    );

    return new UserAccessToken({ ...(payload as any), token });
  }

  static async fromRequest(request: NRequest): Promise<UserAccessToken> {
    const tokenString = areNextRequestHeaders(request.headers)
      ? request.headers.get('Authorization')
      : request.headers.authorization;

    if (!tokenString) {
      throw new AppError(AppErrorCode.USER_ACCESS_TOKEN_MISSING);
    }

    return UserAccessToken.fromString(tokenString.split('Bearer ')[1]);
  }

  hasExpired(): boolean {
    if (differenceInMinutes(new Date(), new Date(this.iat * 1000)) >= 15) {
      return true;
    }

    return false;
  }

  toString(): string {
    return this.token;
  }
}

function areNextRequestHeaders(
  headers: any
): headers is NextRequest['headers'] {
  return typeof headers.get === 'function';
}

export class UserAccessTokenLocalRepository {
  static pendingRefreshPromise?: Promise<void>;

  static async set(accessToken: string): Promise<void> {
    await localforage.setItem(
      Constant.USER_ACCESS_TOKEN_LOCAL_STORAGE_NAME,
      accessToken
    );
  }

  static async remove(): Promise<void> {
    await localforage.removeItem(Constant.USER_ACCESS_TOKEN_LOCAL_STORAGE_NAME);
  }

  static async get(): Promise<string | null> {
    return localforage.getItem<string>(
      Constant.USER_ACCESS_TOKEN_LOCAL_STORAGE_NAME
    );
  }

  static async refresh(): Promise<void> {
    if (!this.pendingRefreshPromise) {
      this.pendingRefreshPromise = this.performRefresh().finally(() => {
        this.pendingRefreshPromise = undefined;
      });
    }

    return this.pendingRefreshPromise;
  }

  private static async performRefresh(): Promise<void> {
    const oldAccessToken = await this.get();

    if (!oldAccessToken) {
      throw new AppError(AppErrorCode.USER_ACCESS_TOKEN_MISSING);
    }

    const { accessToken } = await fetch('/api/users/me/access/refresh', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${oldAccessToken}`,
      },
    }).then((res) => res.json());

    await this.set(accessToken);
  }
}
