export const Constant = new Proxy(
  {
    BASE_URL: process.env.BASE_URL!,
    DATABASE_URL: process.env.DATABASE_URL!,
    NOTION_OAUTH_API_BASE_URL: process.env.NOTION_OAUTH_API_BASE_URL!,
    NOTION_CLIENT_ID: process.env.NOTION_CLIENT_ID!,
    NOTION_CLIENT_SECRET: process.env.NOTION_CLIENT_SECRET!,
    NOTION_OAUTH_CALLBACK_URL: process.env.NOTION_OAUTH_CALLBACK_URL!,
    JWT_SECRET_KEY: process.env.JWT_SECRET_KEY!,
    NODE_ENV: process.env.NODE_ENV,
    MAILGUN_API_KEY: process.env.MAILGUN_API_KEY!,

    USER_ACCESS_TOKEN_LOCAL_STORAGE_NAME:
      process.env.NEXT_PUBLIC_USER_ACCESS_TOKEN_LOCAL_STORAGE_NAME!,

    E2E_USER_ACCESS_TOKEN: process.env.E2E_USER_ACCESS_TOKEN!,
    E2E_HEADLESS: process.env.E2E_HEADLESS === 'false' ? false : true,
  },
  {
    get: function (target, name: string | symbol) {
      if (name in target && (target as any)[name] !== undefined) {
        return (target as any)[name];
      }

      if (name !== '$$typeof') {
        throw Error(`${name.toString()} is missing in environment`);
      }
    },
  }
);
