export enum AppErrorCode {
  UNKNOWN = 'E0001',
  USER_ACCESS_TOKEN_MISSING = 'E0002',
  USER_ACCESS_TOKEN_EXPIRED = 'E0003',
  INVALID_USER_ROLE = 'E0004',
  USER_NOT_FOUND = 'E0005',
  INVALID_EMAIL = 'E0006',
  NOTION_CREDENTIAL_NOT_FOUND = 'E0007',
  RECURRING_EVENT_NOT_FOUND = 'E0008',
  INVALID_NOTION_PROPERTY = 'E0009',
  INVALID_NOTION_PAGE = 'E0010',
  UNIQUE_KEY_CANNOT_BE_DUPLICATED = 'E0011',
  INVALID_WEEKLY_RECURRING_EVENT = 'E0012',
  FORBIDDEN = 'E0013',
  QUEUED_BULK_EDITS_LIMIT_REACHED = 'E0014',
  JOB_COULD_NOT_BE_QUEUED = 'E0015',
  INVALID_BULK_EDIT_TYPE = 'E0016',
  INVALID_BULK_EDIT_STATUS = 'E0017',
  BULK_EDIT_NOT_FOUND = 'E0018',
  PLAN_LIMIT_EXCEEDED = 'E0019',
}

const AppErrorCodeMessages: Partial<Record<AppErrorCode, string | undefined>> =
  {
    E0001: 'Something unexpected happened',
    E0002: 'User access token missing',
    E0003: 'User access token has expired',
    E0004: 'Invalid user role',
    E0005: 'User not found',
    E0006: 'Invalid email',
    E0007: 'Notion credential not found',
    E0008: 'Recurring event not found',
    E0009: 'Invalid notion property',
    E0010: 'Invalid notion page',
    E0011: 'Unique key cannot be duplicated',
    E0012: 'Invalid weekly recurring event',
    E0013: 'Current user does not have enough privileges',
    E0014: "You can't queue more bulk edits",
    E0018: 'Bulk edit not found',
    E0019: 'Plan limit exceeded',
  };

const AppErrorCodeHints: Partial<Record<AppErrorCode, string | undefined>> = {
  E0002: 'try to sign in again',
  E0003: 'try to sign in again',
  E0006: 'provide a valid email',
  E0007: 'try to sign in again',
  E0009: 'try with another notion property',
  E0010: 'try with another notion page',
  E0012: 'make sure to select at least one day under the "Repeat on" section',
  E0014: 'wait until your queued bulk edits finish',
  E0019: 'upgrade your plan to perform this action',
};

export class AppError extends Error {
  public readonly code: AppErrorCode;

  constructor(code: AppErrorCode, message?: string) {
    super(message || AppErrorCodeMessages[code]);
    this.code = code;
  }

  static getMessage(error: any): string {
    return (
      error?.message ||
      AppErrorCodeMessages.E0001 ||
      'Something unexpected happened'
    );
  }

  static getHint(error: any): string {
    const errorCode = error?.code;

    // @ts-ignore
    return `Please ${AppErrorCodeHints[errorCode] || 'try again'}`;
  }

  static getMessageAndHint(error: any): string {
    return `${AppError.getMessage(error)}. ${AppError.getHint(error)}.`;
  }

  static isEqual(error: any, code: AppErrorCode): boolean {
    return error?.code === code;
  }
}
